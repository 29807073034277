import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              ZooHarmony <span style={{color: '#ffffff45'}}>||</span> <span className="text-color-primary">ETHDenver 2022 Virtual BUIDLathon</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
                Your Favorite NFT Marketplace You Have Not Seen Yet
                </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                <Button tag="a" color="dark" wideMobile href="https://alfajores.zoo-harmony.com" target="_blank">
                    View on Celo Network
                </Button>
                <Button tag="a" color="dark" wideMobile href="https://polygon.zoo-harmony.com" target="_blank">
                    View on Polygon Network
                </Button>
                <Button tag="a" color="dark" wideMobile href="https://harmonyone.zoo-harmony.com" target="_blank">
                    View on Harmony Network
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          <div className="hero-figure reveal-from-bottom illustration-element-01 container-xs" data-reveal-value="20px" data-reveal-delay="800">
            <span style={{fontSize: '38px', color: '#5e5b54'}}>VIDEO DEMO</span>
            <ButtonGroup>
            <div className='demo1'>
              <a href="https://youtu.be/UF6i4cwEVKY" target="_blank">How to Mint NFT</a>
            </div>
            <div className='demo1'>
              <a href="https://youtu.be/hfFroZuNLc8" target="_blank">Sale & Gifting NFT</a>
            </div>
            <div className='demo1'>
              <a href="https://youtu.be/BAfUjxzvsI0" target="_blank">Like/Buy NFT</a>
            </div>
            <div className='demo1'>
              <a href="https://youtu.be/oisFJ60vSKM" target="_blank">Edit Profile</a>
            </div>
            <div className='demo1'>
              <a href="https://youtu.be/hgh1t9uSL0M" target="_blank">How to Stake</a>
            </div>
                </ButtonGroup>

          </div>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
